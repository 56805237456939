import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'UpcomingTournaments',
      component: () => import('@/views/UpcomingTournaments.vue'),
      meta: {
        pageTitle: 'Próximas americanas',
        requiresAuth: true 
      },
    },
    {
      path: '/upcoming-canarias',
      name: 'UpcomingCanarias',
      component: () => import('@/views/UpcomingCanarias.vue'),
      meta: {
        pageTitle: 'Próximas canarias',
        requiresAuth: true 
      },
    },
    {
      path: '/tournament/:tournament',
      name: 'tournament',
      component: () => import('@/views/Tournament.vue'),
      meta: {
        pageTitle: 'Viendo torneo',
        requiresAuth: true 
      },
    },
    {
      path: '/players/:tournament',
      name: 'players',
      component: () => import('@/views/Players.vue'),
      meta: {
        pageTitle: 'Listado participantes',
        requiresAuth: true 
      },
    },
    {
      path: '/player-tournaments',
      name: 'PlayerTournaments',
      component: () => import('@/views/PlayerTournaments.vue'),
      meta: {
        pageTitle: 'Tus Torneos',
        requiresAuth: true 
      },
    },

    {
      path: '/player-historial/:user',
      name: 'PlayerHistorial',
      component: () => import('@/views/PlayerHistorial.vue'),
      meta: {
        pageTitle: 'Historial Torneos',
        requiresAuth: true 
      },
    },
   
    {
      path: '/player-tournament/:tournament',
      name: 'PlayerTournament',
      component: () => import('@/views/PlayerTournament.vue'),
      meta: {
        pageTitle: 'Viendo torneo',
        requiresAuth: true 
      },
    },  
    {
      path: '/player-rankings',
      name: 'PlayerRankings',
      component: () => import('@/views/PlayerRankings.vue'),
      meta: {
        pageTitle: 'Rankings',
        requiresAuth: true 
      },
    },  
    {
      path: '/player-rankings/tournament-ranking/:tournament',
      name: 'TournamentRanking',
      component: () => import('@/views/TournamentRanking.vue'),
      meta: {
        pageTitle: 'Ranking cualitativo',
        requiresAuth: true 
      },
    },  
  
   
    {
      path: '/rankings/club-ranking/quantitative-ranking',
      name: 'QuantitativeClubRanking',
      component: () => import('@/views/QuantitativeClubRanking.vue'),
      meta: {
        pageTitle: 'Ranking Club Cuantitativo',
        requiresAuth: true 
      },
    },
    {
      path: '/rankings/club-ranking/qualitative-ranking',
      name: 'QualitativeClubRanking',
      component: () => import('@/views/QualitativeClubRanking.vue'),
      meta: {
        pageTitle: 'Ranking Club Cualitativo',
        requiresAuth: true 
      },
    },
   
    // {
    //   path: '/profile',
    //   name: 'profile',
    //   component: () => import('@/views/profile.vue'),
    //   meta: {
    //     pageTitle: 'Perfil de Usuario',
    //     requiresAuth: true 
    //   },
    // },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
   
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach(async (to, from, next) => {
  await store.dispatch("auth/getUser")
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/currentUser']) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
